import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { injectIntl } from 'react-intl';
import {
  Grid,
  Stack,
  useTheme,
  useMediaQuery,
  Typography,
} from '@mui/material';

import * as styles from './connect.module.scss';

const Connect = ({ intl: { formatMessage } }) => {
  const theme = useTheme();
  const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div className={styles.connect}>
      <Grid container spacing={3} className={styles.container}>
        <Grid item container xs={12} order={1} justifyContent="center">
          <Stack spacing={2} width={[1, 1, 0.8, 0.7, 0.6]} mb={2}>
            <Typography variant="h3" className={styles.typography_h3}>
              {formatMessage({ id: 'businessTalentWhyWorkTitle' })}
            </Typography>
            <Typography
              variant="h6"
              className={styles.connect__container__subtitle}
            >
              {formatMessage({ id: 'businessTalentWhyWorkContent' })}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4} xl={5} order={2} alignSelf="center">
          <div className={styles.background_images}>
            <StaticImage
              src="../../../images/highlight_11.webp"
              alt="highlight"
              className={styles.connect__highlight_1}
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
              formats={['auto', 'webp', 'avif']}
              objectFit="contain"
            />
          </div>
          <Grid container justifyContent="center">
            {matchesLgDown ? (
              <StaticImage
                src="../../../images/connect_1.webp"
                alt="why work 1"
                placeholder="blurred"
                loading="lazy"
                layout="constrained"
                formats={['auto', 'webp', 'avif']}
                objectFit="contain"
                className={styles.connect__container__img}
              />
            ) : (
              <StaticImage
                src="../../../images/connect_1.webp"
                alt="why work 1"
                placeholder="blurred"
                loading="lazy"
                layout="constrained"
                formats={['auto', 'webp', 'avif']}
                objectFit="contain"
                className={styles.connect__container__img}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8} xl={7} order={3} alignSelf="center">
          <Stack spacing={1.5}>
            <Typography
              variant="h6"
              className={styles.connect__container__paragraph}
            >
              {formatMessage({ id: 'businessTalentWhyWork1Paragraph1' })}
            </Typography>
            <Typography
              variant="h6"
              className={styles.connect__container__paragraph}
            >
              {formatMessage({ id: 'businessTalentWhyWork1Paragraph2' })}
            </Typography>
            <Typography
              variant="h6"
              className={styles.connect__container__paragraph}
            >
              {formatMessage({ id: 'businessTalentWhyWork1Paragraph3' })}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

Connect.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Connect);
