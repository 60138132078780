// extracted by mini-css-extract-plugin
export var background_images = "style-module__background_images___BOe4I";
export var body_background = "#f8f9fa";
export var card = "style-module__card___iZ2Iy";
export var chip = "style-module__chip___EkBDz";
export var container = "style-module__container___Rr2If";
export var container_weAre = "style-module__container_weAre___qNbup";
export var container_weAre__content_weAre = "style-module__container_weAre__content_weAre___eqc98";
export var container_weAre__content_weAre__container_items = "style-module__container_weAre__content_weAre__container_items___+olF5";
export var container_weAre__content_weAre__container_items__card = "style-module__container_weAre__content_weAre__container_items__card___lc+YG";
export var container_weAre__content_weAre__container_items__card__content_img = "style-module__container_weAre__content_weAre__container_items__card__content_img___gZiPA";
export var container_weAre__content_weAre__container_items__card__content_img__img = "style-module__container_weAre__content_weAre__container_items__card__content_img__img___vHi7L";
export var container_weAre__content_weAre__container_items__card__quotes = "style-module__container_weAre__content_weAre__container_items__card__quotes___gX6m9";
export var container_weAre__content_weAre__container_items__card__text = "style-module__container_weAre__content_weAre__container_items__card__text___Ynyji";
export var container_weAre__content_weAre__container_items__card__title_card = "style-module__container_weAre__content_weAre__container_items__card__title_card___VXOG4";
export var container_weAre__content_weAre__container_items__content_weAre = "style-module__container_weAre__content_weAre__container_items__content_weAre___fiCe1";
export var container_weAre__content_weAre__content_btn = "style-module__container_weAre__content_weAre__content_btn___6UeU6";
export var container_weAre__content_weAre__subtitle = "style-module__container_weAre__content_weAre__subtitle___TZirf";
export var container_weAre__content_weAre__text_btn = "style-module__container_weAre__content_weAre__text_btn___KGtAl";
export var container_weAre__content_weAre__title = "style-module__container_weAre__content_weAre__title___GF4wr";
export var container_weAre__content_world = "style-module__container_weAre__content_world___fyMZ7";
export var container_weAre__content_world__img = "style-module__container_weAre__content_world__img___PdQmC";
export var content = "style-module__content___4-KaE";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "style-module__full_img___ei6wC";
export var full_imgSmall = "style-module__full_img--small___8tqEa";
export var gray_21 = "style-module__gray_21___C5wC0";
export var image_container = "style-module__image_container___Iasm9";
export var image_item = "style-module__image_item___0GZV+";
export var lg = "1200px";
export var logo = "style-module__logo___V1dR8";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "style-module__primary___IYdv3";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "style-module__title___OB5Pn";
export var typography_h1 = "style-module__typography_h1___Wd+0B";
export var typography_h2 = "style-module__typography_h2___KmTGR";
export var typography_h3 = "style-module__typography_h3___8Z4zT";
export var white = "#fff";
export var white_button = "style-module__white_button___85B0t";
export var xl = "1536px";
export var xxl = "2500px";