import React from 'react';
import { Timeline } from '../../../../components';
import { StaticImage } from 'gatsby-plugin-image';

const data = [
  {
    title: 'professionalJobHowWorkStep1Title',
    content: 'professionalJobHowWorkStep1Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_read.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/document_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'professionalJobHowWorkStep2Title',
    content: 'professionalJobHowWorkStep2Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_desk.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/assigment_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'professionalJobHowWorkStep3Title',
    content: 'professionalJobHowWorkStep3Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_ai.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/ia_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'professionalJobHowWorkStep4Title',
    content: 'professionalJobHowWorkStep4Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_document.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/document_ready_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'professionalJobHowWorkStep5Title',
    content: 'professionalJobHowWorkStep5Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_group.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/group_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
  {
    title: 'professionalJobHowWorkStep6Title',
    content: 'professionalJobHowWorkStep6Content',
    image: (
      <StaticImage
        src="../../../../images/how_work_skills.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
    mobileImage: (
      <StaticImage
        src="../../../../images/boss_mob.webp"
        objectFit="cover"
        alt="desk"
        loading="lazy"
        placeholder="blurred"
      />
    ),
  },
];

const HowWorkProfessionals = () => {
  return <Timeline data={data} />;
};

export default HowWorkProfessionals;
