// extracted by mini-css-extract-plugin
export var background_images = "connect-module__background_images___j6HyJ";
export var body_background = "#f8f9fa";
export var card = "connect-module__card___feLW8";
export var chip = "connect-module__chip___DSzn0";
export var connect = "connect-module__connect___CkmjH";
export var connect__container = "connect-module__connect__container___QphlX";
export var connect__container__img = "connect-module__connect__container__img___4y260";
export var connect__container__paragraph = "connect-module__connect__container__paragraph___VpoC8";
export var connect__container__paragraph2 = "connect-module__connect__container__paragraph2___BrfEr";
export var connect__container__subtitle = "connect-module__connect__container__subtitle___pn5O9";
export var connect__container__title = "connect-module__connect__container__title___eEIyI";
export var connect__highlight_1 = "connect-module__connect__highlight_1___7KI8T";
export var connect__subtitle = "connect-module__connect__subtitle___9Eo3h";
export var container = "connect-module__container___fAmXp";
export var content = "connect-module__content___JYkXU";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "connect-module__full_img___ynNuA";
export var full_imgSmall = "connect-module__full_img--small___9BCxu";
export var gray_21 = "connect-module__gray_21___L5HJN";
export var image_container = "connect-module__image_container___Xa1sW";
export var image_item = "connect-module__image_item___XLBkK";
export var lg = "1200px";
export var logo = "connect-module__logo___D-+-8";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "connect-module__primary___K3JZA";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "connect-module__title___Wu6P7";
export var typography_h1 = "connect-module__typography_h1___woaMi";
export var typography_h2 = "connect-module__typography_h2___uGQOV";
export var typography_h3 = "connect-module__typography_h3___l7f6G";
export var white = "#fff";
export var white_button = "connect-module__white_button___mLlCV";
export var xl = "1536px";
export var xxl = "2500px";