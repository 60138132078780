import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { StaticImage } from 'gatsby-plugin-image';
import { Typography } from '@mui/material';
import IGroup from '../../../../images/group_dark.webp';
import ISearch from '../../../../images/search.webp';
import IKnowledge from '../../../../images/knowledge.webp';
import * as styles from './style.module.scss';

const WeAre = ({ intl: { formatMessage } }) => {
  const items = [
    {
      id: 1,
      icon: IGroup,
      title: formatMessage({ id: 'professionalsWeAreEnterTitle' }),
      text: formatMessage({ id: 'professionalsWeAreEnterText' }),
    },
    {
      id: 2,
      icon: ISearch,
      title: formatMessage({ id: 'professionalsWeAreSearchTitle' }),
      text: formatMessage({ id: 'professionalsWeAreSearchText' }),
    },
    {
      id: 3,
      icon: IKnowledge,
      title: formatMessage({ id: 'professionalsWeAreKnowledgeTitle' }),
      text: formatMessage({ id: 'professionalsWeAreKnowledgeText' }),
    },
  ];

  return (
    <section className={styles.container_weAre}>
      <div className={styles.container_weAre__content_world}>
        <StaticImage
          src="../../../../images/aboutUs/world.webp"
          className={styles.container_weAre__content_world__img}
          alt="world"
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={['auto', 'webp', 'avif']}
        />
      </div>
      <div className={styles.container_weAre__content_weAre}>
        <Typography
          variant="h3"
          className={styles.container_weAre__content_weAre__title}
        >
          {formatMessage({ id: 'professionalsWeAreTitle' })}
        </Typography>
        <Typography
          variant="h6"
          className={styles.container_weAre__content_weAre__subtitle}
        >
          {formatMessage({ id: 'professionalsWeAreSubtitle' })}
        </Typography>
        <div className={styles.container_weAre__content_weAre__container_items}>
          {items.map((comment) => (
            <div
              key={comment.id}
              className={
                styles.container_weAre__content_weAre__container_items__card
              }
            >
              <div
                className={
                  styles.container_weAre__content_weAre__container_items__card__content_img
                }
              >
                <img
                  src={comment.icon}
                  alt="icon"
                  className={
                    styles.container_weAre__content_weAre__container_items__card__content_img__img
                  }
                />
              </div>
              <div
                className={
                  styles.container_weAre__content_weAre__container_items__card__quotes
                }
              >
                <StaticImage
                  src="../../../../images/aboutUs/quote.webp"
                  alt="quote"
                  placeholder="blurred"
                  loading="lazy"
                  layout="constrained"
                  formats={['auto', 'webp', 'avif']}
                />
              </div>
              <h5
                className={
                  styles.container_weAre__content_weAre__container_items__card__title_card
                }
              >
                {comment.title}
              </h5>
              <p
                className={
                  styles.container_weAre__content_weAre__container_items__card__text
                }
              >
                {comment.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

WeAre.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(WeAre);
